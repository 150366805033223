export class TranslationKeys {
    public static readonly STARTS_WITH = 'startsWith';
    public static readonly CONTAINS = 'contains';
    public static readonly NOT_CONTAINS = 'notContains';
    public static readonly ENDS_WITH = 'endsWith';
    public static readonly EQUALS = 'equals';
    public static readonly NOT_EQUALS = 'notEquals';
    public static readonly LT = 'lt';
    public static readonly LTE = 'lte';
    public static readonly GT = 'gt';
    public static readonly GTE = 'gte';
    public static readonly IS = 'is';
    public static readonly IS_NOT = 'isNot';
    public static readonly BEFORE = 'before';
    public static readonly AFTER = 'after';
    public static readonly CLEAR = 'clear';
    public static readonly APPLY = 'apply';
    public static readonly MATCH_ALL = 'matchAll';
    public static readonly MATCH_ANY = 'matchAny';
    public static readonly ADD_RULE = 'addRule';
    public static readonly REMOVE_RULE = 'removeRule';
    public static readonly ACCEPT = 'accept';
    public static readonly REJECT = 'reject';
    public static readonly CHOOSE = 'choose';
    public static readonly UPLOAD = 'upload';
    public static readonly CANCEL = 'cancel';
    public static readonly DAY_NAMES = 'dayNames';
    public static readonly DAY_NAMES_SHORT = 'dayNamesShort';
    public static readonly DAY_NAMES_MIN = 'dayNamesMin';
    public static readonly MONTH_NAMES = 'monthNames';
    public static readonly MONTH_NAMES_SHORT = 'monthNamesShort';
    public static readonly TODAY = 'today';
    public static readonly WEEK_HEADER = 'weekHeader';
}